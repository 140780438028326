import React, { Fragment, useEffect, useState } from "react";
import Title from "../../../text/title";
import Toaster from "../../../common/Toaster";
import LinkText from "../../../text/linkText";
import RoundedBtn from "../../../button/roundedBtn";
import "./style.scss";
import OptionCard from "../../optionCard";
import Input from "../../../input";
import NavRight from "../../../button/navRight";
import NavLeft from "../../../button/navLeft";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";
import { updateCurrentBrand as updateBrand } from "../../../../actions/brand/action";
import { brandAge as brandAgeConstant } from "../../../../util/constants";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";

const BrandMissionComponent = ({ updateBrand, age, brandId }) => {
  const [isMobile, setMobile] = useState(false);
  const [localstorageUserState, setLocalstorageUserState] = useState({});
  const [brandAge, setBrandAge] = useState([]);
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const options = [
    "Organic & Recycle",
    "Ultra Cozy",
    "Made In U.S.",
    "Ethical & Sustainable",
  ];

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const localstorageUser = JSON.parse(
      localStorage.getItem("registeringUser")
    );
    setLocalstorageUserState(localstorageUser);
    setBrandAge(
      localstorageUser && localstorageUser.brandMission
        ? localstorageUser.brandMission
        : ""
    );

    if (!localstorageUser) {
      if (!localstorageUser || !localstorageUser.name) {
        navigate("/onboarding/step-one");
      } else if (!localstorageUser || !localstorageUser.state) {
        navigateBack();
      }
    }
  }, []);

  const onSubmit = async () => {
    if (brandAge.length<1) {
      setToastrMsg("Please choose!");
      doRefresh((prev) => prev + 1);
      return;
    }
    localstorageUserState.brandMission = brandAge;
    localStorage.setItem(
      "registeringUser",
      JSON.stringify(localstorageUserState)
    );
    var updateBrandResonse = await updateBrand(brandId, {
      brandMission: brandAge,
    },true);
    if (updateBrandResonse && !updateBrandResonse.error) {
      // navigate("/onboarding/brand-type");
      navigate("/onboarding/registration-successful");
    } else {
      setToastrMsg("Unable to add Business type");
      doRefresh((prev) => prev + 1);
    }
  };

  const navigateBack = () => {
    navigate("/onboarding/business-type");
  };

  const setBrandAgeHandler = (type, value) => {
    if (type === "radio") {
      for (let ba of brandAge) {
        if (!options.includes(ba)) {
          setBrandAge([]);
        }
      }
      if (brandAge.includes(value)) {
        let tempBrandAge = [...brandAge];
        tempBrandAge.splice(
          tempBrandAge.findIndex((e) => e === value),
          1
        );
        setBrandAge(tempBrandAge);
      } else {
        if (brandAge.length === 4) {
          setToastrMsg("Maximum 4 choices allowed");
          doRefresh((prev) => prev + 1);
          return;
        }
        setBrandAge([...brandAge, value]);
      }
    } else {
      setBrandAge([value]);
    }
  };
  return (
    <Fragment>
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <div className="onboarding row age">
        <div className="top">
          <img src="/images/common/plus_new_logo.svg" />
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image">
          <div className="d-flex justify-content-center margin-top-img left-flex">
            
            {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
            <Title className="hide-mobile" text="Brand mission?" />
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
          <div className="brand-focus-heading hide-desktop">
            <Title text="Brand mission?" />
          </div>
          <div className="brand-focus-heading">
            <Title className="small-title" text="Can select up to 4 choices!" />
          </div>
          <div className="row option-spacing mob-spacing">
            <div className="col-6 col-sm-4 mid-option">
              <div
                className={`option-card option-med`}
                style={
                  brandAge.includes("Organic & Recycle")
                    ? { background: "#31ffea" }
                    : null
                }
                name="Organic & Recycle"
                onClick={() => setBrandAgeHandler("radio", "Organic & Recycle")}
              >
                Organic
                <br /> & Recycle
                {/* <span className="opt-info">Less then 1yr</span> */}
              </div>
            </div>
            <div className="col-6 col-sm-4 mid-option">
              <div
                className={`option-card option-med`}
                style={
                  brandAge.includes("Ultra Cozy")
                    ? { background: "#31ffea" }
                    : null
                }
                name="Ultra Cozy"
                onClick={() => setBrandAgeHandler("radio", "Ultra Cozy")}
              >
                Ultra Cozy
                {/* <span className="opt-info">2+ yrs</span> */}
              </div>
            </div>
          </div>
          <div className="row option-spacing mob-spacing">
            <div className="col-6 col-sm-4 mid-option">
              <div
                className={`option-card option-med`}
                style={
                  brandAge.includes("Made In U.S.")
                    ? { background: "#31ffea" }
                    : null
                }
                name="Made In U.S."
                onClick={() => setBrandAgeHandler("radio", "Made In U.S.")}
              >
                Made In
                <br />
                U.S.
                {/* <span className="opt-info">Less then 1yr</span> */}
              </div>
            </div>
            <div className="col-6 col-sm-4 mid-option">
              <div
                className={`option-card option-med`}
                style={
                  brandAge.includes("Ethical & Sustainable")
                    ? { background: "#31ffea" }
                    : null
                }
                name="Ethical & Sustainable"
                onClick={() =>
                  setBrandAgeHandler("radio", "Ethical & Sustainable")
                }
              >
                Ethical &<br />
                Sustainable
                {/* <span className="opt-info">Less then 1yr</span> */}
              </div>
            </div>
          </div>
          <div className="row option-spacing mob-spacing">
            <input
              type="text"
              placeholder="Another, type here "
              className="type-own"
              onChange={(e) => setBrandAgeHandler("text", e.target.value)}
              value={
                brandAge[0] && !options.includes(brandAge[0])
                  ? brandAge[0]
                  : ""
              }
            />
          </div>

          <div className={!isMobile ? "row" : ""}>
            {/* <div className="col-12 col-md-6 col-sm-6 col-xl-6 to-right hpx"></div> */}
            <div className="col-12 margin-mobile to-left">
              <div className="nav-conatiner">
                <div>
                  <span
                    className="text-decoration-none"
                    onClick={() => navigateBack()}
                  >
                    <NavLeft />
                    <span className="nav-text">Back</span>
                  </span>
                </div>
                <div>
                  <span
                    className="text-decoration-none"
                    onClick={() => onSubmit()}
                  >
                    <span className="nav-text">Next</span>
                    <NavRight />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ brand }) => ({
  age: brand.age,
  brandId: brand.brandId,
});

export default connect(mapStateToProps, { updateBrand })(BrandMissionComponent);
