import React from "react";
import BrandMissionComponent from "../../components/cards/onboardingCard/brand-mission";
import "./style.scss";


const BrandMission = () => {
  return (
    <div className="bg bg-img">
      <div className="mid-card card-4">
        <BrandMissionComponent />
      </div>
      {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
    </div>
  );
};

export default BrandMission;
